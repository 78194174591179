﻿import { stickyHeader } from "@lyse-as/ice-style/ts/02-organisms";

function LoadSticky(): void {
  const noStickyHeader = document.getElementById("ts-noStickyHeader") as HTMLInputElement;

  if (noStickyHeader.value === "true") {
    return;
  }

  const stickyContainer = document.querySelector(".sticky-container-block");
  if (stickyContainer) {
    return;
  }

  document.querySelector(".main-header-container").classList.add("main-header-container--sticky");

  setTimeout(() => stickyHeader());
}

LoadSticky();
